import React, { useState, useRef, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import "./sidebar.component.css";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdMenu, MdTag, MdNotifications } from "react-icons/md";
import { IoLogIn, IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import * as FcIcons from "react-icons/fc";
import { FaIdCardAlt } from "react-icons/fa";
import { BiCollapse } from "react-icons/bi";
import { Collapse } from "bootstrap";
import { switchUrls } from "../../api";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const gateway_frontend = switchUrls("gateway_frontend");
const Sidebarcontent = ({
  open,
  onClose,

  handleAllTags,
  handleActiveInactive,
  handleLostFound,
  showNotifications,
  showLock
}) => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const toggleButtonRef = useRef(null);
  const sidebarRef = useRef(null);
  const { tag_number } = useParams();
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShow = () => {
    window.location.href = gateway_frontend;
  };
  const handleToggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleCloseSidebar = () => {
    if (isCollapsed) {
      // console.log(isCollapsed, "close");
      setIsCollapsed(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      //event.preventDefault();

      // console.log(event.target, "clickend eve");

      if (
        isMobile &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        // console.log(toggleButtonRef, "clickd");
        handleCloseSidebar();
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile, handleToggleSidebar]);
  document.addEventListener("DOMContentLoaded", () => {
    let lastScrollTop = 0;
    const sidebar = document.querySelector(".sidebar");

  });

  const handleNotifications = () => {
    window.location.href =
      gateway_frontend + "/#/scan-notification/" + localStorage.getItem("uid");
  };
  const handlelock = () => {
    window.location.href = "/#/bag-lock/" + tag_number;
  };
  const handledetails = () => {
    window.location.href = "/#/vehicle-details/" + tag_number;
  };
  // const handleShopNow = () => {
  //   window.location.href = "/shop"; // Redirect to the shop page
  // };
  return (
    <div className={`app ${isCollapsed ? "sidebar-collapsed" : ""}`}>
      {isMobile && (
        <button
          ref={toggleButtonRef}
          className="mobile-toggle-button"
          onClick={handleToggleSidebar}
        >
          <IoMenuOutline />
        </button>
      )}
      <div
        className={`sidebar ${isCollapsed ? "collapsed" : ""}`}
        ref={sidebarRef}
      >
        {!isMobile && (
          <button
            ref={toggleButtonRef}
            className="toggle-button"
            onClick={handleToggleSidebar}
          >
            <IoMenuOutline />
          </button>
        )}
        <div className="sidebar-menu">
          <div className="menu-item" onClick={handleShow}>
            <img
              className="sidebar-icons"
              src={
                "https://storage.googleapis.com/pettag/qr/assets/activate.png"
              }
              alt="Pet Icon"
            />
            <span>ACTIVATE TAG</span>
          </div>
          <div className="menu-item" onClick={handleNotifications}>
            <img
              className="sidebar-icons"
              src={
                "https://storage.googleapis.com/pettag/qr/assets/notificaiton.png"
              }
              alt="Pet Icon"
            />
            <span>NOTIFICATIONS</span>
          </div>
          {!location?.pathname?.includes("/vehicle-activation") && (
            <>
              <div className="menu-item" onClick={handledetails}>
                <img
                  className="sidebar-icons"
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/activate.png"
                  }
                  alt="Pet Icon"
                />
                <span>VEHICLE DETAILS</span>
              </div>
           
            
            </>
          )}

          {/* <div className="shop-now-button-container">
            <button className="shop-now-button" onClick={handleShopNow}>
              Shop Now
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebarcontent;
